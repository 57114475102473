import React, { useEffect, useState } from "react";
import "./training.css";
import { CloseCircle } from "iconsax-react";
import { Button, Input, SelectInput } from "../custom-inputs/CustomInputs";
import { AxiosGet, AxiosPost } from "../../api/api";
import MultiSelect from "../custom-inputs/MultiSelect";

const TrainingForm = ({ displayForm, setDisplayForm, item }) => {
  const [trCat, setTrCat] = useState("All");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [trGender, setTrGender] = useState("");
  const [trBasic, setTrBasic] = useState("");
  const [sucMsg, setSucMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [trData, setTrData] = useState([]);
  const [data, setData] = useState({
    email: "",
    name: "",
    howDidYouKnow: "",
    address: "",
    phoneNumber: "",
    gender: "",
    basicKnowledge: "",
  });
  const [dataErr, setDataErr] = useState({
    email: "",
    name: "",
    howDidYouKnow: "",
    address: "",
    phoneNumber: "",
    gender: "",
    basicKnowledge: "",
    trainingIds: "",
  });
  const clickClose = () => {
    setDisplayForm(false);
    setData({
      email: "",
      name: "",
      howDidYouKnow: "",
      address: "",
      phoneNumber: "",
      gender: "",
      basicKnowledge: "",
    })
  };

  const url = `training/?trainingCategory=${trCat}&timeStatus=${"Active"}`;
  const postUrl = `application/general/`;

  const getTraining = () => {
    AxiosGet(url)
      .then((res) => {
        // console.log(res);
        setTrData(res.data);
      })
      .catch((err) => {
        // console.log(err.response);
      });
  };

  useEffect(() => {
    getTraining();
    // eslint-disable-next-line
  }, [trCat]);

  const genderArray = [
    {
      id: "",
      label: "Gender",
    },
    {
      id: "male",
      label: "Male",
    },
    {
      id: "female",
      label: "Female",
    },
    {
      id: "others",
      label: "Others",
    },
  ];
  const trainigArray = [
    {
      id: "All",
      label: "All category",
    },
    {
      id: "Vocational skills",
      label: "Vocational skills",
    },
    {
      id: "Technical skills",
      label: "Technical skills",
    },
    {
      id: "Digital skills",
      label: "Digital skills",
    },
    {
      id: "Creative skills",
      label: "Creative skills",
    },
  ];
  const trDataArray = trData?.map(item => ({
    id: item.id,
    label: item.title,
  }));
  const basicArray = [
    {
      id: "",
      label: "Select",
    },
    {
      id: "true",
      label: "Yes",
    },
    {
      id: "false",
      label: "No",
    },
  ];

  const handleOptChange = (e) => {
    setTrCat(e.target.value);
  };
  const handleGenChange = (e) => {
    setDataErr({
      ...dataErr,
      gender: "",
    });
    setTrGender(e.target.value);
  };
  const handleBasicChange = (e) => {
    setDataErr({
      ...dataErr,
      basicKnowledge: "",
    });
    setTrBasic(e.target.value);
  };
  const handleChange = (e) => {
    setDataErr(prevErr => ({
        ...prevErr,
        [e.target.id]: "",
    }))
    var newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  };

  const dataObj = {
    trainingIds: !item ? selectedOptions.map(item => item.id).join(', ').split(',').map(file => file.trim()) : [item?.id],
    email: data?.email,
    name: data?.name,
    howDidYouKnow: data?.howDidYouKnow,
    address: data?.address,
    phoneNumber: data?.phoneNumber,
    gender: trGender,
    basicKnowledge: trBasic,
  };

  const submitData = () => {
    setIsLoading(true);
    AxiosPost(postUrl, dataObj)
      .then((res) => {
        // console.log(res);
        setSucMsg(res.message);
        setIsLoading(false);
        setTimeout(() => {
          setDisplayForm(false);
          setSucMsg("");
          setData({
            email: "",
            name: "",
            howDidYouKnow: "",
            address: "",
            phoneNumber: "",
            gender: "",
            basicKnowledge: "",
          })
        }, 3000);
      })
      .catch((err) => {
        // console.log(err.response);
        for (let i = 0; i < err.response.data.errors.length; i++) {
          const fieldName = err.response.data.errors[i].fieldName;
          const error = err.response.data.errors[i].error;
          setDataErr((prevDataErr) => ({
            ...prevDataErr,
            [fieldName]: error,
          }));
        }
        setIsLoading(false);
      });
  };

  return (
    <div className="form-overlay" style={{ display: !displayForm && "none" }}>
      <div className="close-form" onClick={clickClose}>
        <CloseCircle size={36} />
      </div>
      <div className="application-form">
        <h2>{item?.title || "Application Form"}</h2>
        {!item && (
          <SelectInput
            label={"Select Training Category"}
            optionArray={trainigArray}
            onChange={(e) => handleOptChange(e)}
            small={true}
          />
        )}
        {!item && (
        //   <SelectInput
        //     label={"Select Training"}
        //     optionArray={trDataArray}
        //     onChange={(e) => handleTrChange(e)}
        //     small={true}
        //     // error={dataErr?.trainingIds}
        //   />
          <MultiSelect selected={selectedOptions} setSelected={setSelectedOptions} formLabel={"Select Training"} selectPlaceholder={"Select Training"} optionsArray={trDataArray} 
          dropHeight="180px" />
        )}
        <Input
          label={"Email Address"}
          placeholder={"Enter your email address"}
          id={"email"}
          value={data?.email}
          onChange={(e) => handleChange(e)}
          small={true}
          error={dataErr?.email}
        />
        <Input
          label={"Full Name"}
          placeholder={"Enter your full name"}
          id={"name"}
          value={data?.name}
          onChange={(e) => handleChange(e)}
          small={true}
          error={dataErr?.name}
        />
        <Input
          label={"How did you know about us?"}
          placeholder={"How did you know about us"}
          id={"howDidYouKnow"}
          value={data?.howDidYouKnow}
          onChange={(e) => handleChange(e)}
          small={true}
          error={dataErr?.howDidYouKnow}
        />
        <Input
          label={"Address"}
          placeholder={"Enter your address"}
          id={"address"}
          value={data?.address}
          small={true}
          onChange={(e) => handleChange(e)}
          error={dataErr?.address}
        />
        <Input
          label={"Phone Number"}
          placeholder={"Enter your phone number"}
          id={"phoneNumber"}
          value={data?.phoneNumber}
          small={true}
          onChange={(e) => handleChange(e)}
          error={dataErr?.phoneNumber}
        />
        <SelectInput
          label={"Gender"}
          small={true}
          optionArray={genderArray}
          onChange={(e) => handleGenChange(e)}
          error={dataErr?.gender}
        />
        <SelectInput
          label={"Do you have basic knowledge of this training?"}
          optionArray={basicArray}
          onChange={(e) => handleBasicChange(e)}
          small={true}
          error={dataErr?.basicKnowledge}
        />
        <div className="app-submit">
          <Button
            btnClass={"btn app-btn"}
            btnText={"Submit"}
            submitBtn={submitData}
            loading={isLoading}
            success={sucMsg}
          />
        </div>
      </div>
    </div>
  );
};

export default TrainingForm;
